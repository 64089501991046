import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import AppLayout from '../../component/appLayout';
import {
  Group,
  Container,
  Headerblock,
  Heading,
  Icon,
  Title,
  Content,
  Titlewrap,
  Tabledata,
  Userdetails,
  Username,
  Userdescription,
  Usermail,
  Usernumber,
  Empty,
  Profileicon,
  Userprofile,
  Profile,
  OptionMenu,
  ResponsiveNavbarIcon,
  HeaderLeft,
  PlusIcon,
  Paginations,
  Leftdiv,
  Rightdiv,
  Text,
  Arrowbtn,
  Left,
  Right,
  ShowRow,
  Rowtext,
  Downarrow
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerList, updateCustomerData } from '../../services/customerServices';
import CustomerModal from '../../component/models/customerModal/customerModal';
import {
  clearCustomerDetail,
  setCustomerDetail,
  setCustomerList,
  setFilteredCustomerList
} from '../../actions/customerActions';
import { CustomerDetailsInterface } from '../../interfaces/CustomerInterface';
import { isEmpty } from '../../helpers/common';
import Button from '../../component/button';
import Deletemodal from '../../component/models/deleteModel';
import { setSuccessMessage } from '../../actions/messageActions';
import ModalCustom from '../../component/models/modal';
import darkcustomerempty from '../../assets/images/emptystates/customerdarkempty.svg';
import customerrempty from '../../assets/images/emptystates/customer-empty.svg';
import LoadingState from '../../component/loading/loadingState';
import { useHistory } from 'react-router-dom';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import emptyprofile from '../../../src/assets/images/emptystates/empty-profile.svg';
import darkemptyprofile from '../../../src/assets/images/emptystates/darkemptyprofile.svg';
import EmptyState from '../../component/emptyState';
import { CustomerDetailMoreMenu, MENU_OPTIONS } from '../../global/constants';
import Responsivnavbar from '../../component/navbar/responsivenavbar';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { Dropdown, DropdownItem } from '../../component/Dropdown';

const Customer: React.FC = () => {
  //Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  //States
  const [customerListIndex, setCustomerListIndex] = useState({ start: 1, end: 10 });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    numOfData: 100
  });
  const [customerLoading, setCustomerLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, customer: customerSelector, workspace: workspaceSelector } = stateSelector || {};
  const { customers, filteredCustomerList, newCustomer } = customerSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { themeMode } = settingsSelector;
  //Other variable
  const handleOpen = () => setOpen(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const getImageSource = () => (themeMode?.theme === 'dark' ? darkcustomerempty : customerrempty);
  const existingPageData = UserPreferenceSingleton.getInstance().getCustomerPagination();
  const rowOptions = [10, 20, 50, 100];

  // for close model
  const handleClose = () => {
    dispatch(clearCustomerDetail());
    setOpen(false);
  };

  // manage pagination
  const handleIndexChange = useCallback(
    (customerList: CustomerDetailsInterface[], currentPage: number, numOfData: number) => {
      const startIndex = Math.min((currentPage - 1) * numOfData + 1, customerList?.length ?? 0);
      const endIndex = Math.min(currentPage * numOfData, customerList?.length ?? 0);
      setCustomerListIndex({ start: startIndex, end: endIndex });
    },
    []
  );

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setCustomerLoading(true);
        const storedRowsPerPage = existingPageData?.[workspace_id];
        if (storedRowsPerPage && pageData.numOfData !== Number(storedRowsPerPage)) {
          setPageData({
            ...pageData,
            numOfData: Number(storedRowsPerPage)
          });
        }
        const customerList = await dispatch(getCustomerList(pageData?.currentPage, pageData?.numOfData));
        handleIndexChange(customerList, pageData?.currentPage, pageData?.numOfData);
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setCustomerLoading(false);
    }
  }, [workspace_id, existingPageData, pageData, handleIndexChange, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(setCustomerList([]));
    };
  }, [dispatch]);

  // for edit customer details
  const onClickEdit = useCallback(
    (item: CustomerDetailsInterface) => {
      dispatch(setCustomerDetail(item));
      setOpen(true);
    },
    [dispatch]
  );

  // load pagination data
  const loadPaginationData = useCallback(
    (currentPage: number, numOfData: number) => {
      const startingIndex = numOfData * (currentPage - 1);
      const endingIndex = startingIndex + numOfData > customers?.length ? customers?.length : startingIndex + numOfData;
      const subData = customers.slice(startingIndex, endingIndex);
      dispatch(setFilteredCustomerList(subData));
    },
    [customers, dispatch]
  );

  // handel menu click
  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      const rowsPerPage = parseInt(key);
      const pageData = {
        ...(existingPageData || {}),
        [workspace_id]: rowsPerPage
      };
      UserPreferenceSingleton.getInstance().setCustomerPagination(pageData);
      setPageData((prevPageData) => {
        // Recalculate current page based on the new number of rows per page
        const newPage = Math.floor(((prevPageData.currentPage - 1) * prevPageData.numOfData) / rowsPerPage) + 1;
        const updatedPageData = { ...prevPageData, currentPage: newPage, numOfData: rowsPerPage };
        handleIndexChange(customers, newPage, rowsPerPage);
        loadPaginationData(newPage, rowsPerPage);
        return updatedPageData;
      });
    },
    [customers, existingPageData, handleIndexChange, loadPaginationData, workspace_id]
  );

  // for change page
  const onChangePage = useCallback(
    (direction: 'Forward' | 'Backward') => {
      setPageData((prevPageData) => {
        let updatedPage = prevPageData.currentPage;

        if (direction === 'Forward' && prevPageData.currentPage * prevPageData.numOfData < (customers?.length ?? 0)) {
          updatedPage += 1;
        } else if (direction === 'Backward' && prevPageData.currentPage > 1) {
          updatedPage -= 1;
        }

        handleIndexChange(customers, updatedPage, prevPageData.numOfData);
        loadPaginationData(updatedPage, prevPageData.numOfData);

        return { ...prevPageData, currentPage: updatedPage };
      });
    },
    [customers, handleIndexChange, loadPaginationData]
  );

  // for delete customer
  const onClickDelete = useCallback(
    (item: CustomerDetailsInterface) => {
      dispatch(setCustomerDetail(item));
      setIsDeleteOpen(true);
    },
    [dispatch]
  );

  // for close modal
  const onCloseModal = useCallback(() => {
    dispatch(clearCustomerDetail());
    setIsDeleteOpen(false);
  }, [dispatch]);

  // conform delete customer
  const onDeleteCustomer = useCallback(
    async (item: { id: string }) => {
      try {
        setLoading(true);
        let response;
        if (!isEmpty(item?.id)) {
          response = await dispatch(updateCustomerData(item?.id, { IsArchived: true }));
        }
        if (response) {
          onCloseModal();
          dispatch(setSuccessMessage('Customer deleted successfully!'));
          loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, loadData, onCloseModal]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (key: number | string, item: CustomerDetailsInterface) => {
    key = Number(key);
    if (key === MENU_OPTIONS.EDIT) {
      onClickEdit(item);
    } else if (key === MENU_OPTIONS.DELETE) {
      onClickDelete(item);
    }
  };

  // mange dropdown
  const handleDropdownOpen = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const renderMoreMenuItems = useCallback(
    (item: CustomerDetailsInterface) => {
      return CustomerDetailMoreMenu.map((menuItem) => (
        <DropdownItem
          key={menuItem.key}
          label={menuItem.label}
          onClick={() => handleClick(menuItem.key, item)}
          tone={menuItem.label === 'Delete' ? 'critical' : 'primary'}
        />
      ));
    },
    [handleClick]
  );

  //list of customers
  const customerList = useMemo(() => {
    return filteredCustomerList?.map((item, index: number) => {
      if (!buttonRef?.current[index]) {
        buttonRef.current[index] = React.createRef<HTMLDivElement>();
      }

      return (
        <div style={{ position: 'relative' }} key={item?.id}>
          <Tabledata onClick={() => history.push(`/customer/${item?.id}`)}>
            <Userprofile>
              <Profile>
                {item?.profileImage && <img src={item?.profileImage} alt='profile' width={28} height={28} />}
                {!item?.profileImage && themeMode?.theme === 'dark' && (
                  <img src={darkemptyprofile} alt='darkemptyprofile' width={28} height={28} />
                )}
                {!item?.profileImage && themeMode?.theme !== 'dark' && (
                  <img src={emptyprofile} alt='emptyprofile' width={28} height={28} />
                )}
              </Profile>
              <Userdetails>
                <Username isBlackText={isEmpty(item?.firstName) && isEmpty(item?.lastName)}>
                  {item?.companyName}
                </Username>
                <Userdescription>
                  {item?.firstName} {item?.lastName}
                </Userdescription>
              </Userdetails>
            </Userprofile>
            <Usermail>{item?.email}</Usermail>
            <Usernumber>{item?.phone}</Usernumber>
            <Dropdown
              activeClassName='active'
              content={
                <OptionMenu>
                  <Icon>
                    <SVGIcon
                      name='more-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='none'
                      className='more-icon'
                    />
                  </Icon>
                </OptionMenu>
              }
              trigger='click'>
              {renderMoreMenuItems(item)}
            </Dropdown>
          </Tabledata>
        </div>
      );
    });
  }, [filteredCustomerList, history, renderMoreMenuItems, themeMode?.theme]);

  return (
    <>
      <AppLayout>
        <Group>
          <Container>
            <Headerblock>
              <HeaderLeft>
                <ResponsiveNavbarIcon>
                  <Responsivnavbar />
                </ResponsiveNavbarIcon>
                <Heading>
                  <Icon>
                    <SVGIcon
                      name='customer-header-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className='customer-icon'
                    />
                  </Icon>
                  <Title>Customers</Title>
                </Heading>
              </HeaderLeft>

              {filteredCustomerList?.length > 0 && (
                <Button
                  title='New Customer'
                  iconName='plus-icon'
                  onClick={handleOpen}
                  type='button'
                  iconSize={18}
                  iconViewBox='0 0 18 18'
                  iconColor='#fff'
                  modelbtn={true}
                  className='icon-button'
                />
              )}

              {filteredCustomerList?.length > 0 && !customerLoading && (
                <PlusIcon onClick={handleOpen}>
                  <SVGIcon name='task-plus-icon' width='26' height='26' viewBox='0 0 32 33' className='circle' />
                </PlusIcon>
              )}
            </Headerblock>

            {/* empty state component and loading state component */}

            {filteredCustomerList?.length === 0 && !customerLoading && (
              <Empty>
                <EmptyState
                  header={'You have no customers'}
                  title={'You can add new customer by clicking the button below.'}
                  image={getImageSource()}
                  name={'New Customer'}
                  onButtonClick={handleOpen}
                />
              </Empty>
            )}
            {customerLoading && filteredCustomerList?.length === 0 && <LoadingState />}

            {filteredCustomerList?.length > 0 && (
              <Content>
                <Tabledata isListheader>
                  <Userprofile>
                    <Profileicon>
                      <SVGIcon
                        name='customers-icon'
                        width='21'
                        height='21'
                        viewBox='0 0 21 21'
                        className='customers-icon'
                      />
                    </Profileicon>
                    <Titlewrap>Name</Titlewrap>
                  </Userprofile>
                  <Usermail isListheader>EMAIL</Usermail>
                  <Usernumber isListheader>PHONE</Usernumber>
                  <OptionMenu isListheader>
                    <Icon>
                      <SVGIcon name='' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                    </Icon>
                  </OptionMenu>
                </Tabledata>
                {customerList}
              </Content>
            )}
          </Container>
          {/* <Pagination>
            {customers?.length > 0 && (
              <PaginationAnt
                className='pagination'
                defaultCurrent={1}
                current={pageData.currentPage}
                defaultPageSize={pageData.numOfData}
                showSizeChanger
                total={customers?.length}
                onChange={onChangePagination}
              />
            )}
          </Pagination> */}
          {filteredCustomerList?.length > 0 && (
            <Paginations>
              <Leftdiv>
                <Text>Show</Text>
                <Dropdown
                  content={
                    <ShowRow onClick={() => handleDropdownOpen()}>
                      <Rowtext>{`${pageData.numOfData} Rows`}</Rowtext>
                      <Downarrow>
                        <SVGIcon
                          name='left-arrow-icon'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          className={dropdownOpen ? 'arrow-open stroke-color' : 'stroke-color'}
                        />
                      </Downarrow>
                    </ShowRow>
                  }
                  trigger='click'
                  onOutsideClick={() => setDropdownOpen(false)}>
                  <>
                    {rowOptions.map((rows) => (
                      <DropdownItem
                        key={rows}
                        label={`${rows} Rows`}
                        onClick={() => handleMenuClick({ key: String(rows) })}
                      />
                    ))}
                  </>
                </Dropdown>
              </Leftdiv>
              <Rightdiv>
                <Text>{`${customerListIndex?.start} - ${
                  customerListIndex?.end > customers?.length ? customers?.length : customerListIndex?.end
                } of ${customers?.length}`}</Text>
                <Arrowbtn>
                  <Left onClick={() => onChangePage('Backward')}>
                    <SVGIcon
                      name='left-arrow-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='stroke-color'
                    />
                  </Left>
                  <Right onClick={() => onChangePage('Forward')}>
                    <SVGIcon
                      name='left-arrow-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='stroke-color'
                    />
                  </Right>
                </Arrowbtn>
              </Rightdiv>
            </Paginations>
          )}
        </Group>
      </AppLayout>
      <ModalCustom open={open} onClose={handleClose}>
        <CustomerModal handleCloseModal={handleClose} loadData={loadData} />
      </ModalCustom>
      <ModalCustom open={isDeleteOpen} onClose={onCloseModal} width={334}>
        <Deletemodal
          onClose={onCloseModal}
          onOk={() => onDeleteCustomer(newCustomer)}
          loading={loading}
          modaltitle='Delete Customer?'
          description='Are you sure to want to delete this customer?'
        />
      </ModalCustom>
    </>
  );
};

export default Customer;
