import React from 'react';
import { ProgressBarWrapper, ProgressContainer, ProgressFill, ProgressInfo } from './style';

interface ProgressBarProps {
  percent: number;
  height?: number;
  progressColor: string;
  showInfo: boolean;
  className?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, height, progressColor, showInfo = false, className }) => {
  // Ensure percent is between 0 and 100
  const normalizedPercent = Math.min(Math.max(percent, 0), 100);

  return (
    <ProgressBarWrapper>
      <ProgressContainer height={height} className={className}>
        <ProgressFill percent={normalizedPercent} progressColor={progressColor} />
      </ProgressContainer>
      {showInfo && <ProgressInfo>{normalizedPercent}%</ProgressInfo>}
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
