import styled from 'styled-components';
import { Typography } from '../../global/theme';

const ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ProgressContainer = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => height || 5}px;
  background-color: var(--border-primary);
  border-radius: 999px;
  overflow: hidden;
  position: relative;
`;

const ProgressFill = styled.div<{ percent: number; progressColor?: string }>`
  width: ${({ percent }) => percent}%;
  height: 100%;
  background-color: ${({ progressColor }) => progressColor || 'var(--primary-color)'};
  border-radius: 999px;
  transition: width 0.3s ease-in-out;
`;

const ProgressInfo = styled.div`
  ${Typography.body_sm}
  color: var(--text-primary);
`;

export { ProgressBarWrapper, ProgressContainer, ProgressFill, ProgressInfo };
